<template>
  <nav class="navbar min-w-1400">
    <div class="secondBox max-w-1400">
      <div class="logo">
        <!-- <img
          :src="require('@/page/ceibamóvil/components/img/logo.png')"
          :alt="logo"
          class="logoImg"
        /> -->
        <span class="title">NANO OK ROI-ET COMPANY LIMITED</span>
      </div>
      <ul class="nav-links">
        <li :class="{ 'active-link': isActive('/') }" class="Li1">
          <router-link to="/" class="nav-link1">
            <span class="routeText1">สมัครสินเชื่อ</span>
          </router-link>
        </li>
        <li
          :class="{ 'active-link': $route.path === '/about' }"
          class="Li2"
        >
          <router-link to="/about" class="nav-link2">
            <span class="routeText2">วิสัยทัศน์ พันธกิจ และคุณค่าขององค์กร</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/service' }" class="Li3">
          <router-link to="/service" class="nav-link3">
            <span class="routeText3">เกี่ยวกับเรา</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/protocol' }" class="Li4">
          <!-- <router-link to="/protocol" class="nav-link4">
            <span class="routeText4">Thỏa thuận quyền riêng tư</span>
          </router-link> -->
          <a href="https://rcptiw.roietreportcredit.com/help/privacy_agreement" class="nav-link3" target="_blank">
            <span class="routeText4">นโยบายความเป็นส่วนตัว</span>
          </a>
        </li>
         <!-- <li
          :class="{
            'active-link': ['/questions', '/protocol'].includes($route.path),
          }"
          class="Li4"
        >
          <router-link to="/questions" class="nav-link4">
            <span class="routeText4">Vấn đề Thường Gặp</span>
          </router-link>
        </li> -->
        <!-- <li :class="{ 'active-link': $route.path === '/protocol' }" class="Li5">
          <router-link to="/protocol" class="nav-link5">
            <span class="routeText5">Acuerdo de privacidad</span>
          </router-link>
        </li> -->
      </ul>
    </div>
    <div style="height: 130px"></div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      link1Color: "white",
    };
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
  },
};
</script>

<style scoped>
  .navbar {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: "";
    height: 90px;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  
  .secondBox {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    /* height: 90px; */
    padding: 0 200px;
    background: #FFFFFF;
    /* box-shadow: 0px 3px 12.2px 0 rgba(65, 64, 64, 0.1); */
    /* background-image: url("@/page/bienestarricotecnologia/components/img/NavBg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%; */
    box-shadow: 0px 4px 9.4px 0px rgba(0, 0, 0, 0.05);
  }
  
  .secondBox-bottom {
    /* box-shadow: 0px 1.5px 0px rgba(54, 53, 56, 1); */
  }
  
  .logo {
    font-weight: bold;
    color: black;
    display: flex;
    align-items: center;
  }
  
  .logoImg {
    width: 60px;
    height: 60px;
    margin-left: 30px;
  }
  
  .title {
    width: 700px;
    font-size: 18px;
    font-family: DM Sans-Bold;
    font-weight: 600;
    color: #333333;
    /* line-height: 23px; */
    padding-left: 17px;
    /* text-transform: uppercase; */
    height: 24px;
    line-height: 24px;
    /* height: 90px;
    line-height: 90px; */
  }
  
  .routeText1,
  .routeText2,
  .routeText3,
  .routeText4,
  .routeText5 {
    font-size: 16px;
    font-family: SF Pro-Semibold;
    /* font-weight: 400; */
    color: #333333;
    /* opacity: 0.8; */
    /* line-height: 29px; */
    /* text-transform: uppercase; */
  }
  
  
  
  .nav-link1,
  .nav-link2,
  .nav-link3,
  .nav-link4,
  .nav-link5 {
    text-decoration: none;
    margin: 0 auto;
  }
  
  .nav-links {
    list-style: none;
    display: flex;
    align-items: center;
    /* 垂直居中对齐 */
    justify-content: center;
    /* 水平居中对齐 */
    /* height: 100%; */
    height: 39px;
    gap: 10px;
  }
  
  .nav-links li {
    /* border: 1px solid red; */
    display: flex;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    align-items: center;
    /* padding-left: 4px;
    padding-right: 4px; */
    /* padding: 6px; */
    height: 100%;
    /* line-height: 90px; */
  }
  
  .Li1 {
  width: 120px;
}
.Li2 {
  width: 320px;
}
.Li3 {
  width: 120px;
}
.Li4 {
  width: 220px;
}
  
  .Li5 {
    width: 210px;
    /* 这个如果给margin-right底部可能会出现边距的问题 */
  }
  
  .active-link {
    /* border-color: #48B46E; */
    background-color: #2758D9;
    color: #FFFFFF;
    border-radius: 4px;
  }
  
  .active-link .routeText1,
  .active-link .routeText2,
  .active-link .routeText3,
  .active-link .routeText4,
  .active-link .routeText5 {
    font-family: Inter-Extra Bold;
    font-weight: bold;
    color: #FFFFFF;
    /* opacity: 1; */
  }
  
  .active-link .routeText1::after,
  .active-link .routeText2::after,
  .active-link .routeText3::after,
  .active-link .routeText4::after,
  .active-link .routeText5::after {
    content: "";
    display: block;
    /* 初始时下划线可见 */
    /* border-bottom: 4px solid #FF8548;  */
    border-radius: 20px;
    /* 调整下划线的长度 */
    /* width: 50%;  */
    transition: width 0s ease-in-out; /* 添加 transition 效果 */
    /* margin-top: 10px; */
  }
  
  .title {
    /* border: 1px solid red; */
    /* padding-left: 30px; */
    /* width: 600px */
  }
  
  .secondBox {
    padding: 0 100px 0 50px;
  }
  </style>  /* 这个如果给margin-right底部可能会出现边距的问题 */

