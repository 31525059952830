<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1Box max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
        <router-link to="/" class="nav-link1"> </router-link>
        <router-link to="/about" class="nav-link2"> </router-link>
        <router-link to="/service" class="nav-link3"> </router-link>
        <!-- <router-link to="/protocol" class="nav-link4"> </router-link> -->
        <a
          href="https://rcptiw.roietreportcredit.com/help/privacy_agreement"
          class="nav-link4"
          target="_blank"
        >
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/nano/components/img/footer.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      altText: "图片",
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}

img {
  display: block;
}

.imgBox1,
.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}

.img1Box,
.img2Box,
.img3Box,
.img4Box,
.img5Box,
.img6Box {
  width: 100%;
  overflow: hidden;
  background-color: "";
}

.imgItem1,
.imgItem2,
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6 {
  width: 100%;
  margin: auto;
}

.img1Box {
  position: relative;
  margin-top: -2px;
}
.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  /* border: 1px solid red; */
  position: absolute;
  cursor: pointer;
  border-radius: 10px;
}

.nav-link1 {
  top: 14%;
  left: 13.5%;
  width: 9.5%;
  height: 13.2%;
}
.nav-link2 {
  top: 14%;
  left: 28.8%;
  width: 21.8%;
  height: 13.2%;
}
.nav-link3 {
  top: 14%;
  left: 56.4%;
  width: 8.9%;
  height: 13.2%;
}
.nav-link4 {
  top: 14%;
  width: 15.5%;
  left: 71%;
  height: 13.2%;
}
.nav-link5 {
  top: 14%;
  left: 41.5%;
  width: 18%;
  height: 13.2%;
}
</style>
